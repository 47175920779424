import axios from "axios";

const subdomain = window.location.hostname.split('.')[0];
const baseURL = `https://${subdomain}.api.learnmedata.com`;

const httpClient = axios.create({
  baseURL
});

httpClient.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem("persist:root"));
    const { currentUser } = JSON.parse(user.user);
    if (currentUser?.token && config.url !== "/api/v1/users/sign_in") {
      config.headers["Authorization"] = "Bearer " + currentUser.token;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
    config.headers["ngrok-skip-browser-warning"] = "true";
    return config;
  },
  (error) => {
    Promise.reject(error);
    console.log(error);
  }
);
export default httpClient;
